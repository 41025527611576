import "./Login.css";

import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Spin, Checkbox } from "antd";
import AuthService from "../../services/api";
import { Redirect, Link } from "react-router-dom";
import logo from "../../assets/e-cert.svg";
import { passwordSecurity } from "../../utils/general";

const Login = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("certificate-automation-authority-details") !== null
    ) {
      const isLoggedSuccess = JSON.parse(
        localStorage.getItem("certificate-automation-authority-details")
      ).success;
      if (isLoggedSuccess === 1) setIsLoggedIn(true);
    }
  }, []);

  //function is called on - sign in button click
  //enteres this function only if the values for username and password are entered
  const onHandleSubmit = (values) => {
    if (isButtonClicked) return;
    setIsButtonClicked(true);
    //in case if anything is present in local storage/session storage, clear it
    localStorage.clear();
    sessionStorage.clear();
    //initialize spinner
    if (document.getElementById("login-spin") !== null)
      document.getElementById("login-spin").style.display = "block";

    //params to be passed as form data in api
    let params = {};
    params.email = Object.values(values)[0];
    params.password = Object.values(values)[1];

    //login api call
    //update the local storage with the corresponding api result
    //CASE 1 : Incorrect login
    //if the credentials are wrong, then api return 200 with a key "success" as 0
    //In this scenario, show error message on top,
    //make the logged in state variable as false.
    //CASE 2 : Correct login
    //if the credentials are correct, then api return 200 with a key "success" as 1
    //also update the logged in state variable to true.
    AuthService.login("/login", params)
      .then((response) => {
        //hide progress bar
        if (document.getElementById("login-spin") !== null)
          document.getElementById("login-spin").style.display = "none";

        const certificateSectionId = 2,
          settingsSectionId = 5;

        let rolesArray = [],
          certificateSection = [],
          settingsSection = [],
          name,
          email,
          success,
          token,
          userId,
          productLogo,
          password = false,
          companyLogo;

        if (response && response.data) {
          message.destroy()
          if (response.data.success === 0 && response.data.message) {
            message.error(response.data.message,1);
            setIsLoggedIn(false);
          } else if (response.data.success === 1) {
            if (response.data.token) token = response.data.token;
            if (response.data.success) success = response.data.success;

            //combining fist name and last name
            if (response.data.user) {
              name =
                response.data.user["first_name"] +
                " " +
                response.data.user["last_name"];

              userId = response.data.user["id"];
              email = response.data.user["email"];
            }

            //appending the roles to roles array
            if (response.data.roles)
              response.data.roles.map(function (roles) {
                rolesArray.push(roles.id);
              });

            //company/client logo
            if (response.data.company_logo)
              companyLogo = response.data.company_logo;

            //product logo
            if (response.data.product_logo)
              productLogo = response.data.product_logo;

            //password security
            if (
              response.data.password_security !== null ||
              response.data.password_security !== undefined
            )
              password = response.data.password_security;

            if (response.data.modules) {
              //mapping module names
              response.data.modules.map(function (modules) {
                if (
                  modules.section &&
                  modules.section === certificateSectionId
                ) {
                  let certificateDetails = {
                    module_name: modules.module_display_name,
                    module_url: modules.url,
                    key: modules.priority,
                  };
                  certificateSection.push(certificateDetails);
                } else if (modules.section === settingsSectionId) {
                  let settingsDetails = {
                    module_name: modules.module_display_name,
                    module_url: modules.url,
                    key: modules.priority,
                  };
                  settingsSection.push(settingsDetails);
                }
              });
            }

            //add a key and value for "Stay Signed in"
            let responseArray = Object.assign({
              username: name,
              email:email,
              userid: userId,
              role: rolesArray,
              settings: settingsSection,
              certificates: certificateSection,
              clientLogo: companyLogo,
              productLogo: productLogo,
              password: password,
            });

            let authorityDetails = Object.assign({
              token: token,
              success: success,
            });

            // keep me signed in -> true : save the corresponding values in local storage.
            //keep me signed in -> false : save the corresponding values in session storage.
            if (Object.values(values)[2] === true) {
              localStorage.setItem(
                "certificate-automation-authority-details",
                JSON.stringify(authorityDetails)
              );
              localStorage.setItem(
                "certificate-automation-authority",
                JSON.stringify(responseArray)
              );
            } else {
              sessionStorage.setItem(
                "certificate-automation-authority-details",
                JSON.stringify(authorityDetails)
              );
              sessionStorage.setItem(
                "certificate-automation-authority",
                JSON.stringify(responseArray)
              );
            }
            setIsLoggedIn(true);
          }
          setIsButtonClicked(false);
        }
      })
      .catch((error) => {
        setIsButtonClicked(false);
        console.log(error);
        //hide progress bar
        if (document.getElementById("login-spin") !== null)
          document.getElementById("login-spin").style.display = "none";
      });
  };

  if (isLoggedIn) return <Redirect exact to="/dashboard" />;
  return (
    <div className="container" id="container">
      <div id="login-spin" style={{ display: "none", position: "relative" }}>
        <Spin className="spin-user" size="large" />
      </div>
      <div className="form-container sign-in-container">
        <div className="form-container-panel">
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onHandleSubmit}
          >
            <span className="login-account-header">Login Account</span>
            <span className="login-account-sub-header">
              Enter your credentails and get started!!!
            </span>
            <Form.Item
              name="username"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid Username",
                },
                {
                  required: true,
                  message: "Please enter your Username",
                },
              ]}
            >
              <div className="input-credentials-container-username">
                <Input placeholder="User name" className="input-credentials" />
              </div>
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your Password",
                },
              ]}
            >
              <div className="input-credentials-container-password">
                <Input
                  type="password"
                  placeholder="Password"
                  className="input-credentials"
                />
              </div>
            </Form.Item>

            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Keep me signed in</Checkbox>
              </Form.Item>

              <Link
                className="login-form-forgot"
                to="/user/forgot-password"
              >
                Forgot password?
              </Link>
            </Form.Item>
            <Form.Item noStyle>
              <Button
                shape="round"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-panel overlay-right">
            <img className="logo" src={logo} />
            {/* <h3 className="overlay-title">Certificate Automation Tool</h3>
            <p className="overlay-sub-title">
              Enter your personal details and start journey with us.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
