import "./Settings.css";
import React, { Component } from "react";
import { Card, Input, message, Spin } from "antd";
import AuthService from "../../services/api";
import { Switch } from "antd";
import { passwordSecurity, changeStatusForApi } from "../../utils/general";
import { inject, observer } from "mobx-react";

class ConfigurationSettings extends Component {
  constructor() {
    super();
    //state initialization
    this.state = {
      data: [],
      password_security: null,
      email_notifications: null,
    };
  }
  componentDidMount() {
    //api call
    this.fetchSettingsData();
  }

  fetchSettingsData = () => {
    if (document.getElementById("settings-spin") !== null)
      document.getElementById("settings-spin").style.display = "block";
    AuthService.requestMethod("/configurationIndex")
      .then((response) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        if (response && response.data) {
          this.setState({
            data: response.data,
          });

          Object.entries(response.data).forEach(([key, value]) => {
            if (value.key && value.key === "password_security") {
              this.setState({
                password_security: passwordSecurity(value.value),
              });
            }

            if (value.key && value.key === "email_notifications") {
              this.setState({
                email_notifications: passwordSecurity(value.value),
              });
            }
          });
        }
      })
      .catch((error) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        console.log(error);
      });
  };

  handleBlur = (key, value, e) => {
    if (value === e.target.value) return;
    const formData = new FormData();
    formData.append(key, e.target.value);

    this.updateConfigurationData(formData);
  };

  toggleOnChange = (key, checked) => {
    const formData = new FormData();
    formData.append(key, changeStatusForApi(checked));

    if (key === "password_security") {
      this.setState({
        password_security: checked,
      });
    }

    if (key === "email_notifications") {
      this.setState({
        email_notifications: checked,
      });
    }

    this.updateConfigurationData(formData);
  };

  updateConfigurationData = (body) => {
    message.destroy();
    if (document.getElementById("settings-spin") !== null)
      document.getElementById("settings-spin").style.display = "block";
    AuthService.requestWithPost("/configurationUpdate", body)
      .then((response) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        if (response && response.data && response.data.status === "success") {
          message.success(response.data.message);
        } else if (response.data.status === "error") {
          message.success(response.data.message);
        }
      })
      .catch((error) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        console.log(error);
      });
  };

  render() {
    const { data, password_security, email_notifications } = this.state;
    const { isFromLocalStorage } = this.props.dataStore.header;
    if (password_security) {
      if (isFromLocalStorage)
        localStorage.setItem(
          "certificate-password-security",
          password_security
        );
      else
        sessionStorage.setItem(
          "certificate-password-security",
          password_security
        );
    }

    return (
      <div className="configuration-settings-container">
        <div
          id="settings-spin"
          style={{ display: "none", position: "relative" }}
        >
          <Spin className="spin-user" size="large" />
        </div>
        <Card className="certificate-profile-header">
          Configuration Settings{" "}
        </Card>
        <div className="configuration-settings-divider" />
        <div className="configuration-settings-wrapper">
          {data.map((item, index) => (
            <>
              {item.key === "password_security" ||
              item.key === "email_notifications" ? (
                <>
                  <div className="configuration-settings-header" key={index}>
                    {item.text}
                  </div>
                  <div className="configuration-settings-input">
                    {item.key === "password_security" ? (
                      <Switch
                        checked={password_security}
                        onChange={this.toggleOnChange.bind(this, item.key)}
                      />
                    ) : (
                      <Switch
                        checked={email_notifications}
                        onChange={this.toggleOnChange.bind(this, item.key)}
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="configuration-settings-header" key={index}>
                    {item.text}
                  </div>
                  <div className="configuration-settings-input">
                    <Input
                      defaultValue={item.value}
                      onBlur={this.handleBlur.bind(this, item.key, item.value)}
                    />
                  </div>
                </>
              )}
            </>
          ))}
        </div>
      </div>
    );
  }
}

export default inject("dataStore")(observer(ConfigurationSettings));
