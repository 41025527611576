//certificate type drop down
export const certificateTypeDropDown = [
  { label: "Select Certificate Type", value: "SCT" },
  { label: "Certificate New", value: "CN" },
  { label: "Statement of Attainment", value: "S" },
  { label: "Certificate", value: "C" },
  { label: "Failed Certificate", value: "F" },
  { label: "Failed Certificate New", value: "FN" },
  { label: "Course Progress Advice", value: "P" },
  { label: "Graduate Certificate New", value: "SN" },
];

// certificate status drop down
export const certificateStatus = [
  {label : "Stage 1 - Certificates Requested" , value : 0},
  {label : "Stage 2 - Certificates Approved" , value : 1},
  {label : "Stage 3 - Certificates Signed" , value : 3},
  {label : "Stage 4 - Certificates Issued" , value : 4},
  {label : "Stage 5 - Certificates Printed " , value : 5},
  {label : "Certififcates Rejected" , value : 2},

]
