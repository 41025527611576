import "./DashboardSearch.css";
import React, { useState, useRef, useEffect } from "react";
import { Input, Button, Select, DatePicker, message } from "antd";
import { inject, observer } from "mobx-react";
import { certificateStatus } from "../../utils/constants";
import AsyncCsv from "../ExportCsv/ExportCsv";
import Confirmation from "./Confirmation";
import { certificateTypeDropDown } from "../../utils/constants";

const { Option } = Select;
const { RangePicker } = DatePicker;
const signee = 4;

const DashboardSearch = (props) => {
  const [courseId, setCourseId] = useState([]);
  const [requisitionerId, setrequisitionerId] = useState([]);
  const [dateStringArray, setDateString] = useState();
  const [credentailDateArray, setCredentialDate] = useState();
  const [studentName, setStudentName] = useState("");
  const [campusName, setCampusName] = useState("");
  const [certificateType, setCertificateType] = useState([]);
  const [certificate, setCertificate] = useState();
  const [credentialNumber, setCredentialNumber] = useState("");
  const childRef = useRef();

  const onBulkSignApiCall = (from) => {
    const { selectedCheckboxCourses, selectedCheckboxStatus } =
      props.dataStore.certificates;

    message.destroy();

    if (from === "print") {
      if (
        selectedCheckboxCourses &&
        selectedCheckboxCourses.length > 1 &&
        selectedCheckboxStatus &&
        selectedCheckboxStatus.length > 1
      ) {
        message.error(
          "All student certificates must be under same certificate status and same certificate template"
        );
      } else if (
        selectedCheckboxCourses &&
        selectedCheckboxCourses.length > 1
      ) {
        message.error(
          "All student certificates must be under same certificate template"
        );
      } else if (selectedCheckboxStatus && selectedCheckboxStatus.length > 1) {
        message.error(
          "All student certificates must be under same certificate status"
        );
      } else {
        childRef.current.setModalVisibility(true, false, "print");
      }
    } else {
      if (selectedCheckboxCourses && selectedCheckboxCourses.length > 1) {
        message.error(
          "All student certificates must be under same certificate template"
        );
      } else {
        childRef.current.setModalVisibility(true, false);
      }
    }
  };

  const onSearchApiCall = () => {
    if (props.dataStore.certificates.disableSearchButton) return;
    props.dataStore.certificates.disableSearchButton = true;

    //on search click add all the searched values to mobx variables
    if (courseId && courseId.length > 0)
      props.dataStore.certificates.certificateSearchObject.courseId = courseId;

    if (requisitionerId && requisitionerId.length > 0)
      props.dataStore.certificates.certificateSearchObject.requisitionerId =
        requisitionerId;

    if (dateStringArray && dateStringArray.length > 0)
      props.dataStore.certificates.certificateSearchObject.dateArray =
        dateStringArray;

    if (credentailDateArray && credentailDateArray.length > 0)
      props.dataStore.certificates.certificateSearchObject.credentialDateArray =
        credentailDateArray;

    if (certificateType && certificateType.length > 0)
      props.dataStore.certificates.certificateSearchObject.certificateTypeId =
        certificateType;

    if (certificate && certificate.length > 0)
      props.dataStore.certificates.certificateSearchObject.certificate =
        certificate;

    props.dataStore.certificates.certificateSearchObject.studentName =
      studentName;
    props.dataStore.certificates.certificateSearchObject.credentialNumber =
      credentialNumber;

    props.dataStore.certificates.certificateSearchObject.campusName =
      campusName;

    //make the autorun variable true to call the api
    props.dataStore.certificates.certificateFetchData = true;
  };

  const onChange = (value, id) => {
    //if from course drop down -> set the corresponding value in state
    if (value === "course") {
      props.dataStore.certificates.certificateSearchObject.courseId = [];
      setCourseId(id);
    }
    if (value === "requisitioner") {
      props.dataStore.certificates.certificateSearchObject.requisitionerId = [];
      setrequisitionerId(id);
    }

    if (value === "certificate") {
      props.dataStore.certificates.certificateSearchObject.certificate = [];
      setCertificate(id);
    }

    // if certificate type is selected, first option is selected dont pass the value in api,
    //else case pass the value in api
    if (value === "certificateStatus") {
      props.dataStore.certificates.certificateSearchObject.certificateTypeId =
        [];
      setCertificateType(id);
    }
  };

  const datePickerChange = (from, date, dateString) => {
    if (from) setDateString(dateString);
    else setCredentialDate(dateString);
  };

  const inputOnChange = (id, event) => {
    if (id === "student") setStudentName(event.target.value);
    if (id === "credential") setCredentialNumber(event.target.value);
    if (id === "campus") setCampusName(event.target.value);
  };

  const {
    courseDetailsDropDown,
    requisitionerDetailsDropDown,
    selectedCheckBoxRows,
  } = props.dataStore.certificates;

  const { storage } = props.dataStore.header;

  const { from } = props;

  return (
    <div className={`${from}-search-container`}>
      <div className={`${from}-search name`}>
        <span className={`${from}-text name`}>Name</span>
        <Input
          className={`${from}-input name`}
          placeholder="Student Name"
          onChange={inputOnChange.bind(this, "student")}
        />
      </div>

      <div className={`${from}-search course`}>
        <span className={`${from}-text course`}>Course</span>
        <Select
          showSearch
          // style={{ width: 250 }}
          mode="multiple"
          placeholder="Select a course"
          optionFilterProp="children"
          onChange={onChange.bind(this, "course")}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {courseDetailsDropDown
            ? courseDetailsDropDown.map((course) => (
                <Option
                  value={course.value}
                  key={course.value}
                  style={{
                    color: course.value === 0 ? "grey" : "black",
                  }}
                >
                  {course.label}
                </Option>
              ))
            : null}
        </Select>
      </div>

      <div className={`${from}-search name`}>
        <span className={`${from}-text name`}>Campus</span>
        <Input
          className={`${from}-input name`}
          placeholder="Campus Name"
          onChange={inputOnChange.bind(this, "campus")}
        />
      </div>

      <div className={`${from}-search requisitioner`}>
        <span className={`${from}-text requisitioner`}>Requisitioner</span>
        <Select
          showSearch
          mode="multiple"
          // style={{ width: 250 }}
          placeholder="Select requisitioner name"
          optionFilterProp="children"
          onChange={onChange.bind(this, "requisitioner")}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {requisitionerDetailsDropDown
            ? requisitionerDetailsDropDown.map((requisitioner) => (
                <Option
                  value={requisitioner.value}
                  style={{
                    color: requisitioner.value === 0 ? "grey" : "black",
                  }}
                >
                  {requisitioner.label}
                </Option>
              ))
            : null}
        </Select>
      </div>

      <div className={`${from}-search requisitioner`}>
        <span className={`${from}-text requisitioner`}>Certificate Type</span>
        <Select
          showSearch
          // style={{ width: 250 }}
          placeholder="Select Certificate Type"
          optionFilterProp="children"
          onChange={onChange.bind(this, "certificate")}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {certificateTypeDropDown
            ? certificateTypeDropDown.map((certificateType) => (
                <Option value={certificateType.value}>
                  {certificateType.label}
                </Option>
              ))
            : null}
        </Select>
      </div>

      <div className={`${from}-search date`}>
        <span className={`${from}-text date`}>Requested Date</span>
        <RangePicker
          className={`${from}-rangepicker`}
          onChange={datePickerChange.bind(this, true)}
        />
      </div>

      {props.from === "reports" ? (
        <>
          <div className={`${from}-search date`}>
            <span className={`${from}-text date`}>Credential Date</span>
            <RangePicker
              className={`${from}-rangepicker`}
              onChange={datePickerChange.bind(this, false)}
            />
          </div>
          <div className={`${from}-search requisitioner`}>
            <span className={`${from}-text requisitioner`}>
              Certificate Status
            </span>
            <Select
              showSearch
              mode="multiple"
              // style={{ width: 250 }}
              placeholder="Select certificate status"
              optionFilterProp="children"
              onChange={onChange.bind(this, "certificateStatus")}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {certificateStatus
                ? certificateStatus.map((certificate) => (
                    <Option
                      value={certificate.value}
                      style={{
                        color: certificate.value === -1 ? "grey" : "black",
                      }}
                    >
                      {certificate.label}
                    </Option>
                  ))
                : null}
            </Select>
          </div>

          <div className={`${from}-search name`}>
            <span className={`${from}-text name`}>Credential Number</span>
            <Input
              className={`${from}-input name`}
              placeholder="Credential Number"
              onChange={inputOnChange.bind(this, "credential")}
            />
          </div>
        </>
      ) : null}

      {props.from === "reports" ? (
        <>
          <div className={`${from}-search button`}>
            <Button className="search" onClick={onSearchApiCall}>
              SEARCH
            </Button>
            <AsyncCsv from={props.from} />
          </div>
        </>
      ) : (
        <div
          className={`${from}-search button`}
          style={{
            justifyContent: "flex-end",
            // marginRight: 15,
            alignSelf: "center",
          }}
        >
          {props.list === "send-to-print" && selectedCheckBoxRows.length > 0 ? (
            <Button
              className="search"
              onClick={onBulkSignApiCall.bind(this, "print")}
              style={{ alignItems: "center" }}
            >
              BULK PRINT
            </Button>
          ) : null}

          {props.list === "to-be=signed-list" &&
          storage &&
          storage.role.length > 0 &&
          storage.role.includes(signee) &&
          selectedCheckBoxRows.length > 0 ? (
            <Button
              className="search"
              onClick={onBulkSignApiCall}
              style={{ alignItems: "center" }}
            >
              BULK SIGN
            </Button>
          ) : null}

          <Button
            className="search"
            onClick={onSearchApiCall}
            style={{ alignItems: "center" }}
          >
            SEARCH
          </Button>
          <AsyncCsv />
          <Confirmation ref={childRef} />
        </div>
      )}
    </div>
  );
};
export default inject("dataStore")(observer(DashboardSearch));
